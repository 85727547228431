import React from "react";

const Fallback = () => {
  return (
    <div className="flex justify-center items-center">
      <p>wait...</p>
    </div>
  );
};

export default Fallback;
